<template>
  <div class="is-flex is-justify-content-space-between navbar-logged-landing">
    <div>
      <img class="logo_emitus_w_logged" src="@/assets/images/landing/emitus-logo-gray.svg" alt="logo emitus">
    </div>
    <div class="is-flex is-align-items-center">
      <p class="is-hidden-mobile text-basier">{{user.firstname}} {{user.lastname}}</p>
      <AvatarNavBar
        style="z-index: 7;"
        id="avatar_navbar"
        :avatar="user.avatar"
      ></AvatarNavBar>
    </div>
  </div>
</template>

<script>
import AvatarNavBar from '@/components/panel/avatarNavbar'
export default {
  components: {
    AvatarNavBar
  },
  data () {
    return {
      user: {}
    }
  },
  created () {
    // Get User form this.notify
    this.notify.$on('updateUser', (user) => {
      this.user = user
      // console.log('User 66 -->', this.user)
    })
  },
  async mounted () {
    // Ask for data to App
    await this.notify.$emit('giveMeData')
  },
  beforeDestroy () {
    this.notify.$off('updateUser')
  }
}
</script>

<style scoped>

.navbar-logged-landing {
  padding: 0px 22px !important;
  align-items: center;
}
.navbar-logged-landing p {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
}
@media (max-width: 768px) {
  .logo_emitus_w_logged {
    width: 69px;
    margin-top: 16px;
  }
}
</style>
