<template>
  <div class="home">

    <!-- NavBarHeader -->
    <div v-if="!authUser" id="Nav_bar" style="position: absolute; z-index: 11">
      <NavBarHeader/>
    </div>
    <div v-if="authUser" id="Nav_bar" style="position: absolute; width: 100%; z-index: 9">
      <NavbarLogged />
    </div>

    <!-- Splash -->
    <div style="display: flex">

      <!-- content video and filter -->
      <div :class="!authUser ? 'emitus-video-landing max-w-video-desktop' : 'emitus-video-landing max-w-video-desktop-full'" style="position: relative">
        <div class="width-100">
          <b-progress
            id="progressbar"
            class="front"
            style="color:#6E3DEA !important; display: none; margin-bottom: -3px; height: 2px; background-color: rgba(255, 255, 255, 0.5); "
            :type="type"
            :max="100"
            :value="valueVideo"
            @change="displayProgressBar"
          ></b-progress>

          <video
            :src="'https://player.vimeo.com/progressive_redirect/playback/686771701/rendition/720p?loc=external&signature=02120ed0e6f58de25537d0e9b1f3623cac62a67ac10e92fb3b2584c0d76e5ab8'"
            ref="bgvid"
            id="bgvid"
            playsinline
            muted
            autoplay
            loop
          ></video>

          <!-- filter remove -->
          <div
            v-if="filterRemove"
            class="video-height-video-desktop filter-video-landing"
          >
            <div class="m-center content-filter">
              <div class="content-video-center">
                <img
                  id=""
                  class="is-45x45"
                  src="../assets/images/landing/icon-play-desktop.svg"
                />
                <p
                  class="Basier-small-regular mt-2">
                  Cómo funciona
                </p>
                <div
                  class="container-tag-line">
                  <h4
                    class="text-basier-medium title-tag-line white" >
                    Encuentra nuevos clientes
                    <br>
                    y realiza consultas online
                  </h4>
                  <p class="mt-3 text-align-left">
                    Eliges tu disponibilidad y tarifas, nosotros te llevamos clientes para consultas legales a través de video llamada.
                  </p>
                </div>

              </div>
            </div>
          </div>
          <!-- filter remove end-->

          <div id="filer_front" class="filter-front">
            <div class="">
              <div
                id="load"
                @click="displayProgressBar"
                class="invisible-front"
                onclick="document.getElementById('bgvid').currentTime = 0;
                        document.getElementById('load').style.display = 'none';
                        document.getElementById('pause').style.display = 'block';
                        document.getElementById('progressbar').style.display = 'block';
                        document.getElementById('bgvid').muted = false;
                        document.getElementById('btn_landing').style.display = 'none';
                        "
              >
                <div class="center is-horizontal-center">
                  <figure class="image is-64x64">
                    <img src="" />
                  </figure>
                </div>
              </div>

                <!-- v-if="stop_Video" -->
              <div
                id="pause"
                style="display: none"
                class="invisible-front"
                @click="stopVideo"
                onclick="document.getElementById('bgvid').pause();
                        document.getElementById('pause').style.display = 'none';
                        document.getElementById('play').style.display = 'block';
                        document.getElementById('progressbar').style.display = 'none';
                        document.getElementById('btn_landing').style.display = 'block';
                        "

              >
                <img src="../assets/images/landing/invisible.png" />
              </div>

              <div
                id="play"
                style="display: none"
                @click="displayProgressBar"
                class="invisible-front"
                onclick="document.getElementById('bgvid').play();
                        document.getElementById('play').style.display = 'none';
                        document.getElementById('pause').style.display = 'block';
                        document.getElementById('progressbar').style.display = 'block';
                        document.getElementById('btn_landing').style.display = 'none';
                        "
              >
                <div class="center is-horizontal-center">
                  <figure class="image is-64x64">
                    <img src="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>

      <div v-if="!authUser" id="container_btn_landing" class="container-btn-Join-emitus">
        <button
          id="btn_landing"
          style="margin: 0 auto;"
          class="btn-Join-emitus button-primary-extra-medium is-vertical-center"
          @click="ocultarFilter"
          onclick="document.getElementById('bgvid').play();
                          document.getElementById('pause').style.display = 'none';
                          document.getElementById('play').style.display = 'block';
                          document.getElementById('progressbar').style.display = 'none';
                          document.getElementById('Join').style.display = 'block';
                          document.getElementById('btn_landing').style.display = 'none';
                          document.getElementById('filer_front').style.display = 'none';
                          document.getElementById('bgvid').muted = true"

        > Registrarme</button>
      </div>

      <div class="container-more-info">
        <a v-on:click="scrollUnder" style="height: 100%; width: 100%;">
          <span class="light-blue-emitus text-basier is-size-7 mt-6">Cómo funciona</span>
          <div>
          <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L7 7L13 1" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          </div>
        </a>
      </div>
      </div>

      <!-- component join right -->
      <div v-if="!authUser" id="Join" class="join-position-mobile  join-landing container-max-join" style="z-index: 10;">
        <Join />
      </div>

    </div>

    <!-- Main -->
    <div class="is-flex">
      <div class="content-landing-left">

        <!-- Section1: What is emitus -->
        <div id="Seccion_1" class="container-sec-new">
          <div class="container-title-section1">
            <p class="is-size-big-text black text-basier">Cómo funciona</p>
            <div>
              <span class="text-basier black is-size-big-text">A</span>
              <span class="text-basier green is-size-big-text">B</span>
              <span class="text-basier is-size-big-text black">OGADUS?</span>
            </div>
          </div>
          <p
            class="p-sec1-desk container-text-section1 text-nunito dark-gray-emitus"
          >
            Abogadus.com es un marketplace de consultas con abogados a través de videollamada. No sólo estarás visible a miles de clientes potenciales, sino que además cuentas con un sistema de reservas automático que te ahorra tiempo y evita citas incumplidas. Después de las sesiones tus clientes podrán hacer reseñas para que sepan lo buen@ que eres. Lo mejor es que solo pagas si te funciona.
          </p>
        </div>

        <!-- Section2: How it works -->
        <div class="is-flex container-steps">

            <!-- Paso 1 -->
            <div class="step-container">
              <img
                class=""
                src="https://emituscdn.com/icon-profile-stept.svg">
              <p
                class="black is-size-medium-big-text text-basier-bold mt-3">
                1. Crea tu perfil
              </p>
              <p
                class="text-nunito dark-gray-emitus is-size-text-normal mt-4 step-text">
                Cuéntales a tus clientes potenciales sobre tu experiencia y las áreas del derecho en las que te especializas. Lo importante es que sepan por qué deberían contratarte.
              </p>
            </div>
            <!-- Paso 2 -->
            <div class="step-container stept-top stept-left step-top-mobile">
              <img
                class=""
                src="https://emituscdn.com/disponible-icon.png">
              <p
                class="black is-size-medium-big-text text-basier-bold mt-3">
                2. Elige tu disponibilidad
              </p>
              <p
                class="text-nunito dark-gray-emitus is-size-text-normal mt-4 step-text">
                Sabemos lo ocupad@ que estás. Por eso el sistema de reserva automático te permite  elegir fechas y horarios en los que te pueden reservar cita.
              </p>
            </div>
            <!-- Paso 3 -->
            <div class="step-container m-top-stept step-top-mobile">
              <img
                class=""
                src="https://emituscdn.com/calendar-icon-big.svg">
              <p
                class="black is-size-medium-big-text text-basier-bold mt-3">
                3. El cliente reserva cita
              </p>
              <p
                class="text-nunito dark-gray-emitus is-size-text-normal mt-4">
                El cliente interesado podrá reservar y pagar por adelantado, para que tu solo tengas que aprobar la reserva y sabes que el dinero ya está en consigna.
              </p>
            </div>

            <!-- paso 4 -->
            <div class="step-container stept-left m-top-stept step-top-mobile">
              <img
                class=""
                src="https://emituscdn.com/videocall-icon.svg">
              <p
                class="black is-size-medium-big-text text-basier-bold mt-3">
                4. Consulta con el cliente
              </p>
              <p
                class="text-nunito dark-gray-emitus is-size-text-normal mt-4 step-text">
                Te conectas con el cliente a la hora acordada directamente desde la plataforma. Así no tienes que gastar tiempo con problemas técnicos para la video llamada.
              </p>
            </div>

            <!-- paso 5 -->
            <div class="step-container m-top-stept step-top-mobile">
              <img
                class=""
                src="https://emituscdn.com/pago-icon.svg">
              <p
                class="black is-size-medium-big-text text-basier-bold mt-3">
                5. Recibe el pago
              </p>
              <p
                class="text-nunito dark-gray-emitus is-size-text-normal mt-4 step-text">
                Una vez finalizada la Consulta con tu cliente, recibirás el pago en tu billetera virtual. Podrás enviar a tu cuenta bancaria tu saldo en el momento que quieras.

              </p>
            </div>

        </div>

        <!-- Section4: Benefits -->
        <div class="container-beneficios">
          <div class="container-sec-new">
            <img
              class="img-benefit"
              src="https:///emituscdn.com/title-beneficios.svg">
            <p
              class="p-sec1-desk container-text-section1 text-nunito dark-gray-emitus"
            >
              En Abogadus.com aprovechamos los avances de la tecnología para hacerte la vida más sencilla. Encuentra nuevos clientes, organiza tus citas, recibe los pagos y realiza las video llamadas desde un solo lugar. Una forma fácil y práctica para generar ingresos sin salir de casa.
            </p>
          </div>
          <div class="content-beneficio container-benefit-one m-top-benefit">
            <img
              class="image-benefit-one"
              src="https://emituscdn.com/profile-avocato.png">
            <div
              class="content-text-benefit">
              <p
                class="title-benefit text-basier-bold black m-top-title m-left-benefit"
              >
              1. Encuentra <br>nuevos clientes
              </p>
              <p
                class="text-nunito dark-gray-emitus paragraph-benefit step-text">
                Tu perfil estará visible a miles de clientes que están buscando consultas online con abogados especializados. El lugar perfecto para un primer contacto.</p>
            </div>
          </div>

          <div class="content-beneficio container-benefit-two m-top-benefit">
            <img
              class="image-benefit-two"
              src="https://emituscdn.com/agendamiento-avocato.png">
            <div
              class="content-text-benefit">
            <p
              class="title-benefit text-basier-bold black m-top-title m-left-benefit"
            >
            2. Sistema de<br>reservas
            </p>
            <p
              class="text-nunito dark-gray-emitus paragraph-benefit step-text">
              No pierdas tiempo respondiendo mensajes y coordinando citas. En abogadus.com cuentas con un sistema de reserva que automatiza el proceso. </p>
          </div>
          </div>

          <div class="content-beneficio container-benefit-three m-top-benefit">
            <img
              class="image-benefit-one"
              src=" https://emituscdn.com/wallet-avocato-stept.png">
            <div
              class="content-text-benefit">
              <p
                class="title-benefit text-basier-bold black m-top-title m-left-benefit"
              >
              3. Solo pagas<br>si te funciona
              </p>
              <p
                class="text-nunito dark-gray-emitus paragraph-benefit step-text">
                El registro es gratuito y no pagas ninguna suscripción. Sólo cobramos una comisión del 7% del valor del servicio pagado por el cliente.</p>
            </div>
          </div>

          <div class="content-beneficio container-benefit-four m-top-benefit">
            <img
              class="image-benefit-two"
              src="https://emituscdn.com/reviews-avocato.png">
            <div
              class="content-text-benefit">
              <p
                class="title-benefit text-basier-bold black m-top-title m-left-benefit"
              >
              4. Valoraciones que<br>generan credibilidad
              </p>
              <p
                class="text-nunito dark-gray-emitus paragraph-benefit step-text">
                La mejor publicidad es una buena referencia. Por eso con cada sesión tus clientes pueden hacer una reseña para que todos sepan lo buen@ que eres.</p>
            </div>
          </div>

          <div class="content-beneficio container-benefit-two m-top-benefit">
            <img
              class="image-benefit-one"
              src="https://emituscdn.com/email-avocato-step-1.png">
            <div
              class="content-text-benefit">
              <p
                class="title-benefit text-basier-bold black m-top-title m-left-benefit"
              >
              5. Evita las citas<br>incumplidas
              </p>
              <p
                class="text-nunito dark-gray-emitus paragraph-benefit step-text">
                Si tus clientes no asisten a la cita, de igual forma te abonamos el 30% de la sesión. Así creamos un incentivo para que cumplan las citas y no te quedes esperando.</p>
            </div>
          </div>

          <div class="content-beneficio container-benefit-one m-top-benefit">
            <img
              class="image-benefit-two"
              src="https://emituscdn.com/videocall-avocato.png">

            <div class="content-text-benefit">
              <p
                class="title-benefit text-basier-bold black m-top-title m-left-benefit"
              >
              6. Videollamadas<br>integradas
              </p>
              <p
                class="text-nunito dark-gray-emitus paragraph-benefit step-text">
                A veces se pierde tiempo ajustando aspectos técnicos de distintas plataformas de videollamadas. En abogadus.com todo está integrado para que sea fácil y rápido.</p>
            </div>
          </div>

        </div>

      <!-- Section6: Pricing -->
        <div class="section-pricing">
          <div
            class="pricing-margin-container-desktop pricing-margin-container-mobile p-top-pricing" >

            <div class="pricing-w-45 p-t-pricing">

              <img
                class="img-title-pricing"
                src="https://emituscdn.com/precio-svg-title.png">
              <p
                class="pricing-p-33-desktop white pricing-mt-55-mobile  text-nunito-light step-text pricing-text-description">
                ¡Queremos ser tu verdadero socio! En lugar de cobrar una tarifa mensual, solo ganamos una comisión cuando ganas dinero.

              </p>
            </div>
            <div
              class="pricing-mb-185-mobile pricing-text-center-mobile pricing-mt-160-mobile pricing-mt-120-desktop">
              <div class="porcentaje">
                <span
                  class="text-basier-medium white number-pricing">
                  15</span>
                <span
                  class="text-basier-medium white icon-percentage">%</span>
                <p
                  class="text-basier white is-size-medium-extra-big-text span-comision">de comisión</p>
              </div>
            </div>

            <div class="container-img-footer">
              <img
                src="https://emituscdn.com/image-one-footer.png"
                class="one-image-footer mt-6">
              <img
                src="https://emituscdn.com/avocat-image-profesional.png"
                class="ml-5 two-image-footer">
            </div>
          </div>

        </div>

        <!-- Section7: We believe -->
        <div
          class="cierre-footer">
            <div
              class="container-cierre-footer">
              <p
                class="text-basier white text-cierre-footer">
                Una forma de trabajo más flexible y gratificante.</p>
              <img
                class="img-cierre-footer"
                src="https://emituscdn.com/smile-women-avocato.png">
            </div>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <FooterLanding/>
  </div>
</template>

<script>
import NavBarHeader from '@/components/landing/NavBarHeader'
import UserService from '@/services/UserService'
import Join from '@/components/landing/Join'
import FooterLanding from '@/components/landing/FooterLanding'
import NavbarLogged from '@/components/landing/NavbarLogged'
import mixpanel from 'mixpanel-browser'

import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  name: 'Home',
  components: {
    NavBarHeader,
    Join,
    FooterLanding,
    NavbarLogged
  },

  metaInfo () {
    return {
      title: 'Abogadus | Consultas Legales Online',
      meta: [
        {
          name: 'description',
          content: 'Consultas online con los mejores abogados. La forma más simple y eficiente de resolver tus dudas legales.'
        },
        {
          name: 'og:description',
          content: 'Consultas online con los mejores abogados. La forma más simple y eficiente de resolver tus dudas legales.'
        },
        {
          name: 'site_name',
          content: 'Abogadus'
        },
        {
          name: 'og:site_name',
          content: 'Abogadus'
        },
        {
          name: 'image',
          content: 'https://emituscdn.com/Abogadus.jpg'
        },
        {
          name: 'og:image',
          content: 'https://emituscdn.com/Abogadus.jpg'
        },
        {
          name: 'title',
          content: 'Abogadus | Consultas Legales Online'
        },
        {
          name: 'og:title',
          content: 'Abogadus | Consultas Legales Online'
        },

        {
          name: 'MobileOptimized',
          content: 'width'
        },
        {
          name: 'HandheldFriendly',
          content: 'width'
        },
        {
          name: 'apple-mobile-web-app-capable',
          content: 'width'
        },
        {
          name: 'apple-mobile-web-app-status-bar-style',
          content: 'black-translucent'
        },
        {
          name: 'theme-color',
          content: '#000000'
        },
        {
          name: 'apple-mobile-web-app-title',
          content: 'Abogadus'
        },
        {
          name: 'mobile-web-app-capable',
          content: 'yes'
        },
        {
          name: 'og:type',
          property: 'og:type',
          content: 'website'
        }
      ]
    }
  },
  data () {
    return {
      filterRemove: true,
      type: 'is-primary',
      nIntervId: 0,
      valueVideo: null,
      authUser: null,
      user: ''
    }
  },
  created () {
    firebase.auth().onAuthStateChanged(user => {
      this.authUser = user
      // console.log(this.authUser)

      if (!this.authUser) {
        // console.log('3322')
        mixpanel.track('View Sell-My-Time')
      }
    })
  },
  mounted () {
    window.addEventListener('scroll', this.handlerScroll)
    this.filterRemove = true
  },
  methods: {
    handlerScroll () {
      this.onScrolledFilter()
      this.onScrolledComponents()
    },
    displayProgressBar () {
      this.filterRemove = false
      const v = this
      this.nIntervId = setInterval(function () {
        var duration = document.getElementById('bgvid').duration
        var currentTime = document.getElementById('bgvid').currentTime
        v.valueVideo = Math.round((currentTime / duration) * 100)
      }, 500)
    },
    stopVideo () {
      this.filterRemove = true
      mixpanel.track('Stop video')
      // const v = this;
    },
    ocultarFilter () {
      this.filterRemove = false
      window.scroll(0, 0)
    },
    onScrolledFilter () {
      if (!this.authUser) {
        const resp = window.scrollY
        const join = document.getElementById('Join')
        if (join.style.display === 'block') {
          document.getElementById('container_btn_landing').style.display = 'none'
        } else if ((document.getElementById('load').style.display === 'none') && (document.getElementById('play').style.display === 'none')) {
          if (resp > 1) {
            document.getElementById('bgvid').pause()
            this.filterRemove = true
            document.getElementById('progressbar').style.display = 'none'
            document.getElementById('btn_landing').style.display = 'block'
          } else if (resp <= 1) {
            document.getElementById('bgvid').play()
            this.filterRemove = false
            document.getElementById('progressbar').style.display = 'block'
          }
        }
      }
    },
    scrollUnder () {
      window.scroll(0, 600)
    },
    onScrolledComponents () {
      if (!this.authUser) {
        const resp = window.scrollY
        if (resp > 1) {
          document.getElementById('container_btn_landing').classList.add('container-btn-Join-emitus-bottom')
        } else if (resp <= 1) {
          document.getElementById('container_btn_landing').classList.remove('container-btn-Join-emitus-bottom')
        }
        if (resp > 680 && resp < 7450) {
          document.getElementById('Nav_bar').classList.add('nav_bar_fixed')
          document.getElementById('Join').classList.add('hidde-join-mobile')
          document.getElementById('Join').classList.remove('container-max-join')
        } else if (resp <= 680 || resp >= 7450) {
          document.getElementById('Nav_bar').classList.remove('nav_bar_fixed')
          document.getElementById('Join').classList.remove('hidde-join-mobile')
          document.getElementById('Join').classList.add('container-max-join')
        }
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.nIntervId)
    next()
  },
  destroyed () {
    window.removeEventListener('scroll', this.handlerScroll)
    clearInterval(this.nIntervId)
  }
}
</script>

<style scoped>

.subtitle {
  font-size:1.2em;
  color: #ffffff;
  font-weight: 100;
}

.container-more-info {
  position: absolute;
  bottom: 20px;
  width: 100%;
  max-width: 277px;
  margin: auto;
  text-align: center;
  z-index: 5;
}
.nav_bar_fixed {
  position: fixed!important;
  top: 0!important;
  background: rgba(13, 18, 57, 0.53)!important;
}

.progress-wrapper .progress {
  height: 5px!important;
}

.container-tag-line {
  margin-bottom: 21px;
  background-color: rgba(0, 0, 0, 0.5);
  max-width: 601px;
}

 .content-filter {
    padding-top: 0;
  }
/* prueba */
.filter-front {
  width: 100%;
  height: 100vh;
  z-index: 5;
  position: absolute;
  top: 0px;
  opacity: 0;
  background-color: #0d123933;
}
.invisible-front {
  width: 100%;
  height: 100vh;
  z-index: 5;
  top: 0px;
  opacity: 0;
  background-color: #0d123900;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  margin-top: -75px;
}

.is-horizontal-center {
  justify-content: center;
}
.container-btn-Join-emitus {
  position: fixed;
  z-index: 5;
  bottom: 108px;
  width: 100%;
  text-align: center;
}
.container-btn-Join-emitus-bottom {
  bottom: 0!important;
  background:  rgba(255, 255, 255, 0.86)!important;
  height: 70px;
  display: flex;
  align-items: center;
  z-index: 10;
}
.container-btn-Join-emitus-bottom button {
  width: 80%!important;
  margin: 0 auto;
}
.btn-Join-emitus {
  background: #0dc366;
  box-shadow: 0px 4px 8px rgba(17, 23, 71, 0.17);
  border-radius: 0px;
  width: 237px;
  font-size: 14px;
  font-weight: 600;
  height: 45px;
  color: #ffffff;
  border: none;
}

.btn-Join-emitus:hover, .btn-Join-emitus:focus, .btn-Join-emitus:active {
  background: #32af5c;
  transition-timing-function: ease-out;
  transition-delay: 2s;
}

/* BODY */

.bg-img-sec-3 {
  display: flex;
  align-items: flex-end;
  color: #ffffff;
}
.content-img-sec-1 {
  display: flex;
  align-items: flex-end;
  color: #ffffff;
}
.content-img-sec-1 p span {
  font-weight: bold;
}
.section-2-content {
  width: 60%;
  max-width: 325px;
  color: #ffffff;
  position: absolute;
  bottom: 49px;
  left: 45px;
}

.emitus-video-landing {
  display: flex;
  justify-content: center;
}
.emitus-video-landing video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.filter-video-landing {
  position: absolute;
  top: 0;
}
.content-filter {
  color: #ffffff;
  display: flex;
  height: 100vh;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  padding-top: 0px;
}

.section-pricing {
  background-color: #000000;
  width: 100vw;
}

.number-pricing {
  font-size: 176px;
}

.icon-percentage {
  font-size: 116px;
}

.span-comision {
  margin-top: -67px;
  margin-left: 17px;
}

@media only screen and (min-width: 959px) {
  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 540px;
    margin-top: 0px;
  }
  .is-horizontal-center {
    justify-content: center;
  }
  .hero-body {
    padding: 0;
  }

  .hero.is-fullheight {
    background-color: #0d1239;
  }
  .control {
    max-width: 100%;
  }
  .filter-front {
    width: 100%;
    height: 100vh;
    z-index: 5;
    position: absolute;
    top: 0;
    opacity: 1;
    background-color: transparent;
    border-radius: 0px;
    overflow: hidden;
  }

  .invisible-front {
    width: 100%;
    height: 100vh;
    z-index: 5;
    top: 0px;
    opacity: 1;
    background-color: transparent;
  }

}

@media (min-width: 768px) {

  .content-filter {
    padding-top: 150px;
  }

  .container-tag-line {
    background-color: rgba(0, 0, 0, 0.3);
    max-width: 601px;
    padding-left: 26px;
    padding-right: 141px;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-top: 190px;
  }

  .title-tag-line {
    font-size: 35px;
  }

  /* .container-more-info {
    margin-left: 5%;
  } */
  .hidde-join-mobile {
    position: fixed!important;
    top: 0px!important;
  }
  .margin_join_fixed {
    margin: 0 0 0 74%!important;
  }
  .container-btn-Join-emitus  {
    display: none!important;
  }

  .join-landing {
    width: 90% !important;
    top: 0px;
    display: block;
    margin: 144px auto 0 auto;
    /* justify-content: center;
    align-items: center; */
  }
  .max-w-video-desktop {
    max-width: 68%;
  }
  .max-w-video-desktop-full {
    width: 100%;
  }

  .content-video-center h4 {
    font-style: normal;
    font-size: 35px;
    line-height: 50px;
    text-align: left !important;
    width: 450px;
  }
  .container-more-info {
    bottom: 50px;
  }

    .filter-video-landing {
    height: calc(var(--vh, 1vh) * 100);
    background: linear-gradient(
      180.81deg,
      rgba(13, 18, 57, 0.136) 0.66%,
      rgba(13, 18, 57, 0.704) 74.9%,
      rgba(13, 18, 57, 0.8) 99.3%
    );
    z-index: 2;
    width: 100%;
  }
  .filter-invisible {
    height: 100%;
    /* background-color: thistle; */
    background: transparent;
    width: 100%;
    z-index: 2;
    position: relative;
  }

  .video-height-video-desktop {
    height: 100vh;
  }

  .width-100 {
    width: 100%;
  }

  .image-part2-1 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 620.63px;
    height: 327px;
  }

  .image-part2-2 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 360.32px;
    height: 540px;
  }
  .image-part2-3 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 606px;
    height: 453px;
  }
}

@media (max-width: 768px) {

  .join-position-mobile {
    z-index: 0;
    position: absolute;
    top: 0;
    width: 100%;
    display: none;
  }

    .filter-video-landing {
    height: calc(var(--vh, 1vh) * 100);
    background: linear-gradient(
      180.81deg,
      rgba(13, 18, 57, 0.136) 0.66%,
      rgba(13, 18, 57, 0.704) 74.9%,
      rgba(13, 18, 57, 0.8) 99.3%
    );
    z-index: 2;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .filter-invisible {
    height: 100vh;
    /* background-color: thistle; */
    background: transparent;
    width: 100%;
    z-index: 2;
  }

  .content-video-center h4 {
    margin-top: 47px;
    padding-top: 15px;
    font-size: 25px;
    line-height: 35px;
    text-align: left;
    width: 324px;
    margin-left: auto;
    margin-right: auto;
  }
  .container-tag-line {
    width: 91%;
    margin: auto;
    padding-left: 16px;
    padding-right: 15px;
    padding-bottom: 25px;
  }

  .subtitle{
      font-size: 1.1em;
      margin-bottom: 90px;
  }

   .image-part2-1 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 315.2px;
  }
  .image-part2-2 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 591.43px;
    margin: 40px 0;
  }
  .image-part2-3 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 453px;
  }
  .padding-img3-sect-mobile {
    padding-bottom: 134px;
  }



}

/* ---DESKTOP--- */

@media (min-width: 1025px) {
  .visible-desktop {
    display: block;
  }

  .container-max-join {
    max-width: 490px !important;
  }

  .visible-desktop {
    display: block;
  }
}

@media (max-width: 1024px) {
   .hidde-join-mobile {
      display: none!important;
    }
  .bg-img-sec-3 {
    background-image: url("../assets/images/landing/img-sec-3-bg-mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 552px;
    width: 100vw;
    margin-top: 192px;
  }
  .bg-img-sec-3 p {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 61px;
    margin-left: 41px;
  }

  .content-img-sec-1 {
    padding-bottom: 35px;
    padding-left: 35px;
    font-size: 30px;
  }

  .bg-sec-1-mobile {
    width: 100vw;
    height: 610px;
    background: #171717;
    padding-top: 86px;
  }

  .bg-img-sec-1 {
    background-image: url("../assets/images/landing/section2-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 360px;
  }
  .container-mobile-content-sec-new {
    width: 80%;
    margin: 0 auto;
  }

  .container-sec-new {
    width: 82%;
    margin: 0 auto;
    margin-top: 150px;
    max-width: 458px;
  }

  .container-title-section1 p {
    font-size: 30px;
    line-height: 37px;
  }

  .p-sec1-desk {
    margin-top: 50px;
    line-height: 28px;
    font-size: 15px;
  }

  .container-title-section1 span {
    font-size: 30px;
    letter-spacing: 12px;
  }

  .container-sec-new-img {
    text-align: center;
  }

  .container-sec-new h3 {
    font-size: 42px;
    line-height: 51px;
    width: 94%;
    margin: auto;
    max-width: 640px;
  }

  .container-steps {
    flex-wrap: wrap;
    width: 93%;
    max-width: 500px;
    margin: auto;
    margin-top: 100px;
  }

  .step-text {
    font-size: 15px;
    line-height: 28px;;
  }

  .step-top-mobile {
    margin-top: 112px;
  }

  .container-img-footer {
    display: none;
  }

  .step-container {
    width: 90%;
    max-width: 450px;
    margin-left: 25px;
  }

  /*Title Benefit*/

  .img-benefit {
    width: 192px;
  }

  .container-beneficios {
    margin-top: 260px;
  }

  .content-beneficio {
    width: 100vw;
    padding-top: 20px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    margin-top: 105px;
  }

  .container-benefit-one {
    background-color: #FAF5DB;
  }

  .container-benefit-two {
    background-color: #E1F1EB;
  }

  .container-benefit-three {
    background-color: #E2E5FF;
  }

  .container-benefit-four {
    background-color: #FFEFE1;
  }

  .content-text-benefit {
    width: 85%;
    margin: auto;
    max-width: 420px;
  }

  .title-benefit {
    font-size: 25px;
    margin-top: 60px;
    line-height: 30px;
  }

  .paragraph-benefit {
    margin-top: 45px;
  }

  .image-benefit-one, .image-benefit-two {
    width: 90%;
    margin: auto;
    max-width: 458px;
  }

  .container-text-sec1 i img,
  .container-text-sec1-2 i img {
    width: 30px;
    height: 32.83px;
    margin-top: 50px;
    margin-bottom: 18px;
  }
  .container-text-sec1 h4,
  .container-text-sec1-2 h4 {
    /* width: 330px; */
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 37px;
    color: #393939;
    margin-bottom: 22px;
  }
  .container-text-sec1 p,
  .container-text-sec1-2 p {
    font-size: 15px;
    line-height: 31px;
    /* width: 414px; */
  }
  .container-seccion1-mobile {
    width: 70%;
    max-width: 480px;
    margin: 0 auto;
  }

  .content-steps {
    display: flex !important;
  }

  .container-seccion1-mobile h3 {
    margin-top: 111px;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 53px;
    margin-bottom: 146px;
  }
  .container-seccion1-mobile ol li {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
  }
  .container-seccion1-mobile ol {
    padding-left: 20px;
    margin-top: 91px;
  }
  .container-seccion1-mobile p {
    margin-top: 45px;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 203px;
    padding-left: 20px;
  }

  .section-2 .section-2-part1 h3 {
    color: #ffffff;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 53px;
    width: 207px;
    margin: 84px auto 80px auto;
    padding-top: 84px;
  }
  .section-2 .section-2-part1 img {
    width: 300px;
    margin-bottom: 71px;
  }
  .section-2 .section-2-part1 p {
    width: 60%;
    max-width: 325px;
    margin: 0 auto;
    color: rgba(255, 255, 255, 0.76);
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 26px;
    margin-bottom: 90px;
  }
  .section-2-content h5 {
    font-weight: 500;
    font-size: 17px;
    line-height: 33px;
    margin-bottom: 21px;
  }

  .img-title-pricing {
    width: 140px;
  }

  .number-pricing {
    font-size: 130px;
  }

  .icon-percentage {
    font-size: 100px;
  }

  .span-comision {
    margin-top: -50px;
    margin-left: 11px;
  }

  .pricing-margin-container-mobile {
      max-width: 480px;
      width: 75%;
      margin: 155px auto 0 auto;
      padding-top: 155px;

  }

  .pricing-text-size-43-mobile {
    font-size: 43px;
  }

  .pricing-mt-55-mobile {
    margin-top: 55px;
    line-height: 30px;
  }

  .pricing-mt-160-mobile {
    margin-top: 40px;
  }

  .pricing-text-description {
    max-width: 410px;
  }

  .cierre-footer {
    width: 100vw;
    background-color: #000000;
    padding-bottom: 60px;
  }

  .container-cierre-footer {
    display: flex;
    flex-direction: column;
    max-width: 480px;
    width: 90%;
    margin: auto;
    padding-top: 260px;
  }

  .text-cierre-footer {
    max-width: 320px;
    font-size: 42px;
  }

  .img-cierre-footer {
    margin-top: 40px;
  }

  /* css emitus */

  .Image-footer-w-mobile {
    width: 100%;
    padding-bottom: 58px;
  }
  .flex-footer {
    display: flex;
    flex-direction: column;
  }

  .container-img-footer-mobile {
    width: 80%;
    margin: 0 auto;
  }
  .found-sec-footer-mobile {
    background: #171717;
    width: 100%;
  }
  .text-found-sec-footer {
    font-size: 40px;
    line-height: 60px;
    color: #ffffff;
    margin-top: 183px;
    margin-bottom: 166px;
  }
  .footer-img1,
  .footer-img2 {
    margin-bottom: 50px;
  }
  .footer-img3 {
    width: 100%;
    margin-bottom: 88px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .section-2-part1 {
    width: 480px;
    margin: 0 auto;
  }
  .margin-img-part2-table {
    margin: 0 auto;
  }
  .margin-img-part2-2-table {
    margin: 40px auto 210px auto;
  }
  .padding-img3-sect-table {
    padding-bottom: 292px;
  }
}

@media (min-width: 1024px) {
  .bg-img-sec-3 p {
    font-size: 52px;
    line-height: 57px;
    margin-bottom: 70px;
    margin-left: 180px;
  }
  .bg-img-sec-3 {
    background-image: url("../assets/images/landing/img-sec-3-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 761px;
    width: 100vw;
  }
  .content-img-sec-1 {
    padding-bottom: 68px;
    padding-left: 90px;
    font-size: 44.0848px;
  }
  .bg-sec-1 {
    width: 100vw;
    height: 528px;
    background: #171717;
  }
  .bg-img-sec-1 {
    background-image: url("../assets/images/landing/section2-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 927px;
    height: 567.96px;
  }
  .mt-negative-desk {
    position: absolute;
    margin-top: -200px;
  }

  .container-text-sec1 {
    margin-left: 219px;
    margin-top: 226px;
  }
  .container-text-sec1-2 {
    margin-left: 105px;
    margin-top: 226px;
  }
  .container-text-sec1 i img,
  .container-text-sec1-2 i img {
    width: 30px;
    height: 32.83px;
    margin-top: 50px;
    margin-bottom: 18px;
    margin-left: 50px;
  }
  .container-text-sec1 h4,
  .container-text-sec1-2 h4 {
    width: 330px;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 37px;
    color: #393939;
    margin-bottom: 22px;
    margin-left: 50px;
  }
  .container-text-sec1 p,
  .container-text-sec1-2 p {
    font-size: 15px;
    line-height: 31px;
    width: 414px;
    margin-left: 50px;
  }
  .text-desktop-sec1 {
    font-style: normal;
    font-weight: normal;
    font-size: 55px;
    line-height: 70px;
    margin-top: 293px;
    margin-left: -60px;
  }
  .p-sec1-desk {
    margin-top: 51px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    color: #393939;
  }
  .margin-img3-sect-desk {
    margin-left: 18px;
    margin-top: 31px;
  }
  .margin-img2-sect-desk {
    margin-bottom: -51px;
    margin-top: 31px;
  }
  .content-landing-left {
    width: 70%;
  }
  .content-landing-right {
    width: 30%;
  }
  .video-height-video-desktop {
    height: 100vh;
  }

  .container-seccion1-desktop {
    width: 80%;
    margin: 0 auto;
  }
  .container-seccion1-desktop h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 55px;
    line-height: 70px;
    margin-top: 183px;
    margin-bottom: 138px;
  }
  .container-seccion1-desktop ol li {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 38px;
    width: 310px;
  }
  .container-seccion1-desktop p {
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 28px;
    width: 310px;
  }
  .container-seccion1-desktop img {
    margin-right: 65px;
  }
  .flex-desktop {
    display: flex;
  }
  .flex-desktop-reverse {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .container-sec-new {
    max-width: 480px;
    margin: auto;
    margin-left: 137px;
  }

  .container-title-section1 {
    width: 401px;
    margin-top: 185px;
  }

  .container-steps {
    flex-wrap: wrap;
    max-width: 825px;
    margin: auto;
    margin-left: 108px;
    margin-top: 237px;
  }
  .step-container {
    width: 280px;
    margin-left: 25px;
  }

  .stept-top {
    margin-top: -40px;
  }

  .stept-left {
    margin-left: 60px;
  }

  .m-top-stept {
    margin-top: 90px;
  }

  .container-beneficios {
    margin-top: 280px;
  }

  .content-beneficio {
    width: 89vw;
    margin-left: 100px;
    padding-bottom: 30px;
  }

  .m-top-benefit {
    margin-top: 330px;
  }

  .m-left-benefit {
    margin-left: 65px;
  }

  .m-top-title {
    margin-top: 40px;
  }

  .content-beneficio img {
    margin-top: -147px;
  }

  .container-benefit-one {
    background-color: #FAF5DB;
  }

  .image-benefit-one {
    margin-left: 110px;
    width: 519px;
  }

  .container-benefit-two {
    background-color: #E1F1EB;
  }

  .image-benefit-two {
    margin-left: 65px;
    width: 437px;
  }

  .container-benefit-three {
    background-color: #E2E5FF;
  }

  .container-benefit-four {
    background-color: #FFEFE1;
  }

  .title-benefit {
    font-size: 30px;
  }

  .paragraph-benefit {
    font-size: 15;
    line-height: 30px;
    max-width: 414px;
    margin-left: 65px;
    margin-top: 16px;
  }

  .container-title-section1 span {
    letter-spacing: 12px;
  }
  .container-text-section1 {
    width: 440px;
    margin-top: 80px;
  }
  .margin-bottom-img-1-desk {
    margin-bottom: 332px;
  }
  .margin-bottom-img-2-desk {
    margin-bottom: 308px;
  }
  .margin-bottom-img-3-desk {
    margin-bottom: 360px;
  }
  .margin-bottom-img-4-desk {
    margin-bottom: 187px;
  }
  .margin-text-2-desk {
    margin-top: -80px;
    margin-left: -60px;
  }
  .margin-text-2-desk li {
    padding-left: 18px;
  }
  .margin-text-2-desk p {
    padding-left: 18px;
  }
  .text-desktop-paso1 {
    padding-top: 71px;
  }
  .text-desktop-paso1 ol li::marker {
    font-size: 35px;
  }
  .text-desktop-paso1 li {
    padding-left: 20px;
  }
  .text-desktop-paso1 p {
    padding-left: 18px;
    margin-top: 20px;
  }
  .margin-text-2-desk ol li::marker {
    font-size: 35px;
  }
  .text-desktop-paso3 {
    padding-top: 40px;
  }
  .text-desktop-paso3 li {
    padding-left: 18px;
  }
  .text-desktop-paso3 ol li::marker {
    font-size: 35px;
  }
  .text-desktop-paso3 p {
    padding-left: 18px;
    margin-top: 20px;
  }
  .text-desktop-paso4 {
    margin-left: -40px;
  }
  .text-desktop-paso4 li {
    padding-left: 12px;
  }
  .text-desktop-paso4 p {
    margin-top: 24px;
    padding-left: 12px;
  }
  .text-desktop-paso4 ol li::marker {
    font-size: 35px;
  }
  .section-2 {
    padding-top: 157px;
  }
  .section-2-part1 h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 77px;
    color: #ffffff;
    margin-left: 41px;
  }
  .section-2-part1 p {
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 34px;
    color: #ffffff;
    margin-left: 41px;
  }
  .section-2-part2-desk {
    margin-left: 25px;
    margin-top: 97px;
  }
  .section-2-content h5 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 33px;
    margin-bottom: 21px;
  }
  .section-2-content p {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 26px;
    margin-bottom: 40px;
  }

  .p-top-pricing {
    padding-top: 140px;
  }
  .pricing-margin-container-desktop {
    margin-left: 185px;
    margin-top: 142px;
  }

  .pricing-w-45 {
    width: 45%;
  }

  .pricing-p-33-desktop {
    margin-top: 40px;
    line-height: 30px;
    font-size: 15px;
    width: 460px;
  }

  .pricing-mt-120-desktop {
    margin-top: 60px;
  }

  .container-img-footer {
    margin-top: 160px;
  }

  .cierre-footer {
    width: 100vw;
    background-color: #000000;
    padding-bottom: 45px;
  }

  .container-cierre-footer {
    display: flex;
    max-width: 997px;
    margin-left: 180px;
    padding-top: 40px;
  }

  .text-cierre-footer {
    font-size: 40px;
    order: 2;
    max-width: 297px;
    line-height: 45px;
    margin-left: 40px;
    margin-top: 26px;
  }

  .img-cierre-footer {
    width: 523px;
    order: 1;
  }
  .one-image-footer {
    width: 273px;
    height: 100%;
  }

  .Image-footer-w-desktop {
    width: 100%;
  }

  .flex-footer {
    display: flex;
    align-items: flex-end;
    width: 70%;
  }
  .footer-img1 {
    width: 358.11px;
    height: 542px;
    margin: 0 30px 40px 30px;
  }
  .footer-img2 {
    width: 347px;
    height: 444.05px;
    margin-bottom: 40px;
  }
  .footer-img3 {
    margin-left: 66px;
  }
  .found-sec-footer {
    height: 528px;
    background: #171717;
    width: 100vw;
    display: flex;
    align-items: flex-end;
    position: absolute;
    top: 350px;
    z-index: -1;
  }
  .container-found-sec-footer {
    width: 50%;
    margin: 0 0 100px auto;
  }
  .text-found-sec-footer {
    width: 297px;
    font-size: 44px;
    line-height: 65px;
    color: #ffffff;
    margin-left: 113px;
  }
  .is-relative-desktop {
    position: relative;
  }

}
</style>
