<template>
  <!-- box to enter as user -->
  <div
    id="join_"
    class="join-position-mobile  join-container-mobile join-found-mobile join-w-full join-h-full is-flex is-flex-direction-column is-justify-content-space-between w-100"
  >
    <div class="w-100 has-text-right btn-skip-table pt-4 is-hidden-tablet">
    </div>
    <div
      class="join-bg-transparent container-login m-center border-container is-flex is-flex-direction-column"
    >
      <div id="join_container" class="container is-max-desktop not-w-content pb-3 text-color">
        <!-- title -->
        <h3
          class="join-display-none join-title text-basier mt-4 pb-3 has-text-weight-normal has-text-centered black"
        >
          Registro Abogados
        </h3>

        <p
          class="join-display-none dark-gray-emitus mt-2 is-size-small-text has-text-centered dark-gray-emitus paragraph-short"
        >
          Crear tu perfil es gratis y comienzas a  realizar consultas online
        </p>

        <div
          class="buttons-joins buttons align-content-center is-flex not-w-btn is-flex-direction-column container is-max-desktop z-index-full"
        >

          <!-- enter with google -->
          <b-button
            href="#"
            class="shadow-btn signup-with-button big-button join-margin-bottom-content-mobile has-text-weight-medium has-background-white has-text-black btn-shadow"
            type="is-primary"
            expanded
            size=""
            style="font-size: 13px"
            @click="signInWithGoogle"
          >
            <div class="is-flex is-align-items-center">
              <img
                class="is-size-7 ml-1"
                src="../../assets/images/landing/Google.svg"
                alt=""
                style="width: 21px;"
              />
              <p class="ml-3 black">Continúa con Google</p>
            </div>
          </b-button>

          <!-- enter with apple -->
          <b-button
            href="#"
            class="
            shadow-btn
            signup-with-button
            big-button
            join-margin-bottom-content-mobile
            has-text-weight-medium btn-shadow"
            type="is-primary"
            expanded
            size=""
            style="font-size: 13px; background: #000000 !important;"
            @click="signInWithApple"
          >
            <div class="is-flex is-align-self-center shadow-btn">
              <img
                class="is-size-7"
                src="../../assets/images/landing/apple.svg"
                alt=""
                style="width: 19px;"
              />
              <p class="mt-1 ml-3">Continúa con Apple</p>
            </div>
          </b-button>

          <!--- enter with facebook-->
          <b-button
            href="#"
            class="
              signup-with-button
              big-button
              join-margin-bottom-content-mobile
              has-text-weight-medium
              btn-shadow"
            type="is-primary"
            expanded
            size=""
            style="
              background: #E1E1E1 !important;
              font-size: 13px;
              align-items: center;"
            @click="continueSignup"
          >
            <div class="is-flex is-align-self-center">
              <img
                class="ml-3"
                src="https://emituscdn.com/icon-candando.svg"
                alt=""
                style="width: 14px;"
              />
              <span class="mt-1 ml-3 black">Continúa con contraseña</span>
            </div>
          </b-button>

          <!--<p
            class="join-margin-bottom-content-mobile join-content-color text-nunito mt-3 is-size-7 has-text-weight-bold has-text-centered has-text-grey"
          >
            or
          </p>
          register with password
          <div
            @click="continueSignup"
            class="join-margin-bottom-mobile is-max-desktop container has-text-centered mt-3 pb-5"
          >
            <p id="signup" to=""
              class="text-basier link-text-sign-up"
              >Continue with password</p>
          </div>-->
          <!-- accept terms and conditions -->
          <form
            class="mt-5 pb-3 no-padding join-margin-bottom-mobile"
            id="danger-line"
            >
            <label class="checkbox is-flex mb-3">
              <input
                type="checkbox" class=""
                v-model="checkbox"
                v-on:click="checkboxClick"
                checked
                required
              />
              <div>

                <p
                  id="checkboxTerms"
                  class="
                    paragraph-terms-conditions
                    ml-2 mt-3
                    has-text-centered
                    text-nunito
                    dark-gray-emitus"
                >
                  Acepto
                  <router-link
                    target="_blank"
                    to="/terms"
                    class="text-nunito"
                    style="text-decoration: underline"
                    ><a class="link-text-sign-up">términos de uso</a>
                  </router-link>
                  y
                  <router-link
                    target="_blank"
                    to="/privacy"
                    class="text-nunito"
                    style="text-decoration: underline"
                    >
                    <a
                      class="link-text-sign-up">
                      política de privacidad.
                    </a></router-link
                  >
                </p>
              </div>
            </label>
          </form>
        </div>
      </div>
    </div>
    <!-- loader -->
    <div v-if="loader">
      <Loading/>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/modals/loader.vue'
import firebaseConfig from '@/firebase/init.js'
import firebase from 'firebase/app'
import 'firebase/auth'
import UserService from '@/services/UserService'
import dayjs from 'dayjs'
import MixpanelService from '@/services/MixpanelService'
import mixpanel from 'mixpanel-browser'

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  components: {
    Loading
  },
  data () {
    return {
      checkbox: true,
      authUser: null,
      loader: false
    }
  },
  created () {
    firebase.auth().onAuthStateChanged(user => {
      this.authUser = user
      // console.log('--------> uyu', this.authUser)
    })
  },
  mounted () {
    window.addEventListener('scroll', this.onScrolledJoin)
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScrolledJoin)
  },

  methods: {
    // Accept terms & conditions
    checkboxClick (e) {
      this.checkbox = e.target.checked
      if (this.checkbox === true) {
        document.getElementById('checkboxTerms').classList.remove('validationCheck')
      }
    },

    // Signin with Google -------
    async signInWithGoogle () {
      if (this.checkbox === true) {
        const timezone = dayjs.tz.guess()
        const provider = new firebase.auth.GoogleAuthProvider()
        this.loader = true
        await firebase.auth().signInWithPopup(provider)
          .catch(err => {
            this.loader = false
            this.danger(err.message)
          })
        const idToken = await firebase.auth().currentUser.getIdToken(true)

        const newUser = await UserService.ContinueWith(
          idToken,
          this.authUser.displayName,
          this.authUser.email,
          this.authUser.uid,
          timezone
        )
        // console.log('99', newUser)
        if (newUser.status === 201) {
          this.loader = false
          // console.log('11', newUser)
          MixpanelService.signUp(newUser)
          this.notify.$emit('updateData')
          this.$router.push('/welcome')
        } else if (newUser.status === 200) {
          this.loader = false
          // console.log('00', newUser)
          MixpanelService.login(newUser.data)
          // mixpanel.identify(`${newUser.data._id}`)
          this.$router.push('/panel/me/edit-me')
          this.notify.$emit('updateData')
        } else {
          // console.log('55')
          this.loader = false
          this.danger('Hubo un problema de autenticación')
        }
      } else {
        document.getElementById('checkboxTerms').classList.add('validationCheck')
        this.danger('Debes aceptar lso Términos y Política de Privacidad para continuar')
      }
    },

    // Signin with Apple -------
    async signInWithApple () {
      if (this.checkbox === true) {
        const timezone = dayjs.tz.guess()
        const provider = new firebase.auth.OAuthProvider('apple.com')
        await firebase.auth().signInWithPopup(provider)
          .catch(err => {
            this.danger(err.message)
          })

        const idToken = await firebase.auth().currentUser.getIdToken(true)

        let email = this.authUser.email
        const domainEmail = email.substring(email.indexOf('@') + 1)

        if (domainEmail === 'privaterelay.appleid.com') {
          email = ''
        }

        const newUser = await UserService.ContinueWith(
          idToken,
          this.authUser.displayName,
          email,
          this.authUser.uid,
          timezone
        )
        // console.log('99', newUser)
        if (newUser.status === 201) {
          this.loader = false
          console.log('11', newUser)
          MixpanelService.signUp(newUser)
          this.notify.$emit('updateData')
          this.$router.push('/welcome')
        } else if (newUser.status === 200) {
          this.loader = false
          console.log('00', newUser)
          // mixpanel.identify(`${newUser.data._id}`)
          MixpanelService.login(newUser.data)
          this.$router.push('/panel/me/edit-me')
          this.notify.$emit('updateData')
        } else {
          console.log('55')
          this.danger('Hubo un problema de autenticación')
        }
      } else {
        document.getElementById('checkboxTerms').classList.add('validationCheck')
        this.danger('Debes aceptar lso Términos y Política de Privacidad para continuar')
      }
    },

    // Signin with Faceebook -------
    async signInWithFacebook () {
      if (this.checkbox === true) {
        const timezone = dayjs.tz.guess()
        const provider = new firebase.auth.FacebookAuthProvider()
        provider.addScope('email')

        await firebase.auth().signInWithPopup(provider)
          .catch(err => {
            this.danger(err.message)
          })

        const idToken = await firebase.auth().currentUser.getIdToken(true)

        const newUser = await UserService.ContinueWith(
          idToken,
          this.authUser.displayName,
          this.authUser.email,
          this.authUser.uid,
          timezone
        )

        // console.log('99', newUser)
        if (newUser.status === 201) {
          this.loader = false
          console.log('11', newUser)
          MixpanelService.signUp(newUser)
          this.notify.$emit('updateData')
          this.$router.push('/welcome')
        } else if (newUser.status === 200) {
          this.loader = false
          console.log('00', newUser)
          MixpanelService.login(newUser.data)
          // mixpanel.identify(`${newUser.data._id}`)
          this.$router.push('/panel/me/edit-me')
          this.notify.$emit('updateData')
        } else {
          console.log('55')
          this.danger('Hubo un problema de autenticación')
        }

        // if (!signup) {
        //   this.loader = false
        //   this.$router.push('/panel/me/edit-me')
        //   this.notify.$emit('updateData')
        // } else {
        //   this.loader = false
        //   console.log('221', signup)
        //   mixpanel.alias(`${signup.data.User.id}`)
        //   this.notify.$emit('updateData')
        //   this.$router.push('/welcome')
        // }

        // if (signup) {
        //   this.notify.$emit('updateData')
        //   this.$router.push('/welcome')
        // } else {
        //   this.notify.$emit('updateData')
        //   this.$router.push('/panel/me/edit-me')
        // }
      } else {
        document.getElementById('checkboxTerms').classList.add('validationCheck')
        this.danger('Debes aceptar lso Términos y Política de Privacidad para continuar')
      }
    },

    // Logout -------
    async logout () {
      await firebase.auth().signOut()
      // console.log(response)
    },

    continueSignup () {
      if (this.checkbox === true) {
        this.$router.push('/signup')
      } else {
        document.getElementById('checkboxTerms').classList.add('validationCheck')
        this.danger('Debes aceptar lso Términos y Política de Privacidad para continuar')
      }
    },
    onScrolledJoin () {
      const resp = window.scrollY
      if (resp > 680 && resp < 7450) {
        document.getElementById('join_container').classList.add('margin_join_fixed')
        document.getElementById('join_container').classList.add('box_join_fixed')
      } else if (resp <= 680 || resp >= 7450) {
        document.getElementById('join_container').classList.remove('margin_join_fixed')
        document.getElementById('join_container').classList.remove('box_join_fixed')
      }
    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-danger'
      })
    }
  }
}
</script>

<style scoped>
.signup-with-button {
  transition: all 0.1s ease-in-out 0s;
}

.signup-with-button:hover, .signup-with-button:active, .signup-with-button:focus {
  transform: scale(1.04);
}

.validationCheck {
  border-bottom: 1px solid red;
  padding-bottom: 10px;
}
.buttons-joins {
  margin-top: 34px !important;
}

.signup-with-button {
  margin-top: 10px !important;
}

.link-text {
  text-decoration: underline;
  color: #393939;
}

.signup-with-button {
  height: 45px !important;
  border-radius: 0px !important;
  margin-top: 15px;
}

.big-button {
  width: 220px !important;
}

#danger-line {
  border-radius: 10px;
  padding: 1px;
  margin-top: 25px;
}
#danger {
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
}

.paragraph-short {
    max-width: 210px;
    margin: auto;
  }

.paragraph-terms-conditions {
  color: #393939;
  font-size: 13px;
  line-height: 22px;
  max-width: 230px;
}

.link-text-sign-up {
  color: #000;
  text-decoration: underline;
  transition: color 0.2s ease-in;
}

.link-text-sign-up:hover, .link-text-sign-up:active, .link-text-sign-up:focus {
  color:  rgba(13, 18, 57, 0.6)!important;
}

@media (max-width: 768px) {

  .link-text-sign-up {
    color: #fff !important;
    text-decoration: underline;
    transition: color 0.2s ease-in;
  }

  .link-text-sign-up:active, .link-text-sign-up:hover, .link-text-sign-up:focus {
    color: rgb(207, 207, 207);
  }

  .paragraph-terms-conditions {
    color: #fff;
    font-size: 13px;
    opacity: 0.8;
  }

  .join-display-none {
    display: none;
  }
  .join-visibility-hidden {
    visibility: hidden;
  }
  .join-bg-transparent {
    background: transparent !important;
  }
  .join-container-mobile {
    width: 100% !important;
  }
  .join-found-mobile {
    background: linear-gradient(
      180.81deg,
      rgba(13, 18, 57, 0.17) 0.66%,
      rgba(13, 18, 57, 0.88) 74.9%,
      #0d1239 99.3%
    );
  }
  .join-w-full {
    width: 100% !important;
  }
  .join-h-full {
    height: 100vh;
  }
  .join-no-margin-mobile {
    margin: 0;
  }
  .join-margin-bottom-content-mobile {
    margin-bottom: 2px !important;
  }

  .join-margin-bottom-mobile {
    margin-bottom: 15px !important;
  }
  .no-padding {
    padding: 0 !important;
  }
  .z-index-full {
    z-index: 8;
  }
}
@media (min-width: 768px) {

  .join-title {
    font-size: 26px;
  }
  .shadow-btn {
    border: 1px solid #000000;
    /*box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08)!important;*/
  }
  .margin_join_fixed {
    margin: 0 0 0 74%!important;
  }
  .box_join_fixed {
    width: 335px;
    background: #fff;
    border-radius: 0px;
    padding: 45px;
    -webkit-box-shadow: 3px 9px 29px -1px rgba(189,189,189,0.41);
    box-shadow: 3px 9px 29px -1px rgba(189,189,189,0.41);
  }
}
.align-content-center {
  align-content: center;
}
</style>
