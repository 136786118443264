<template>
  <div
    id="nav_bar_home"
    class="is-flex"
    style="
      width: 100vw;
      justify-content: space-between;
      height: 55px;">
    <div
      class="ml-5"
      style="display: flex;">
      <a href="/">
        <img
          id="logo_1"
          class="logo-white"
          src="https://emituscdn.com/LOGO-ABOGADUS..svg"
          alt="logo emitus"
          />
        <img
          id="logo_2"
          class="hidde-logo logo-gray"
          src="https://emituscdn.com/ABOGADUS-black.svg"
          alt="logo emitus"/>
      </a>
    </div>
    <div class="is-flex is-align-items-center is-pulled-right mr-4">
      <div class="is-flex is-acontrol has-icons-left">

        <!-- <router-link to="/login"> -->
        <button
          @click="clickShowContinue"
          class="
          button-login
          button-border-small
          mr-1
          is-size-small-text">
          Ingresar
        </button>
        <!-- </router-link> -->
      </div>
    </div>
    <!-- Continue Modal -->
    <b-modal
        v-model="showContinue"
        full-screen
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal
        :can-cancel="false">

        <div>
          <Continue
            @close="showContinue = false"
          />
        </div>

    </b-modal>
  </div>
</template>

<script>
import Continue from '@/components/modals/continue.vue'
export default {
  name: 'NavBarHeader',
  components: { Continue },
  data () {
    return {
      showContinue: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onScrolledNavbar)
    this.notify.$on('hiddeModalContinue', data => {
      this.showContinue = data
    })
  },
  methods: {
    onScrolledNavbar () {
      const resp = window.scrollY
      if (resp > 680 && resp < 7450) {
        document.getElementById('logo_1').classList.add('hidde-logo')
        document.getElementById('logo_2').classList.remove('hidde-logo')
        document.getElementById('nav_bar_home').classList.add('select_nav')
      } else if (resp <= 680 || resp >= 7450) {
        document.getElementById('nav_bar_home').classList.remove('select_nav')
        document.getElementById('logo_1').classList.remove('hidde-logo')
        document.getElementById('logo_2').classList.add('hidde-logo')
      }
    },
    clickShowContinue () {
      this.showContinue = true
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScrolledNavbar)
    this.notify.$off('hiddeModalContinue')
  }
}
</script>

<style scoped>

.logo-white {
  width: 129px;
  margin-top: 16px;
}

.logo-gray {
  width: 120px;
  margin-top: 26px;
  margin-left: 25%;
  opacity: 0.5;
}

.select_nav {
  background: #FBFBFB!important;
}
.select_nav button {
  background: #FBFBFB;
  border-radius: 0px;
  border: none;
  color: #000;
  margin-top: 0px;
}

.select_nav button:active, .select_nav button:hover, .select_nav button:focus {

  background: #eeeeee;
  color: #000;
  transition: all 0.2s ease-in-out 0s;
}

.hidde-logo {
  display: none;

}

.button-border-small {
  width: 120px;
  height: 38px;
  font-family: "Basier Circle Regular";
  font-size: 12px;
  border: 1px solid #ffff;
  border-radius: 14px;
  background-color: rgba(13, 18, 57, 0.23);
  color: #ffffff;
  transition: all 0.3s ease-in-out 0s;
}

.button-border-small:hover, .button-border-small:focus, .button-border-small:active {
  background-color: rgba(13, 18, 57, 0.6);
  border:none;
  transform: scale(1.04);
}

.language-select {
  background: none !important;
}

.control.has-icons-left .icon, .control.has-icons-right .icon {
  height: 2.8em !important;
}

.select:not(.is-multiple):not(.is-loading)::after {
  margin-top:-0.2em !important;
}
.button-login {
  width: 100px;
  background: rgba(13, 18, 57, 0.14);
  box-sizing: border-box;
  border-radius: 0px;
  margin-top: 10px;
  text-decoration: underline;
  border: none;
}

.button-login:focus, .button-login:hover, .button-login:active {
   background: rgba(13, 18, 57, 0.24);
  box-sizing: border-box;
}


@media (min-width: 768px) {

  .button-login {
    background: #fff;
    border-radius: 0px;
    border: none;
    color: rgb(34, 34, 34);
    text-decoration: underline;
  }

  .button-login:hover, .button-login:active, .button-login:focus {
    background: #ececec;
    color: #000000;
    transition: all 0.2s ease-in-out 0s;
  }
  .select {
    align-items: center;
  }
  .select select {
    border: none !important;
    color: #808080;
  }
  .select select:focus {
    border-color: none !important;
  }
  .select:not(.is-multiple):not(.is-loading)::after {
    border-color: #808080;
  }
  .vector-idioma-blanco {
    display: none;
  }
  .has-icons-left {
    margin-right: 20px;
  }
}

@media (max-width: 768px) {
  #logo_1 {
    width: 120px;
    margin-top: 30px;
  }

  #logo_2 {
    width: 110px;
    margin-top: 24px;
    margin-left: 5%;
  }

  .logo-white {
    width: 69px;
  }

  .logo-gray {
    width: 61px;
  }
  .select select {
    border: none !important;
    color: #ffffff !important;
  }
  .select:not(.is-multiple):not(.is-loading)::after {
    border-color: #ffffff !important;
    margin-top:-0.3em !important;

  }
  .control.has-icons-left .icon img {
    border-color: #ffffff !important;
  }
  .vector-idioma {
    display: none;  }
}
</style>
